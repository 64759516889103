import { Link } from 'react-router-dom';
import {
  ChevronRight,
  Icon,
  Loader
} from 'react-feather';
import './Button.scss';
import { MouseEventHandler } from 'react';

function Button({
  label = "Submit",
  size = "medium",
  color = "default",
  href = void 0,
  target = "_self",
  icon,
  endIcon,
  disabled = false,
  loading = false,
  onClick = () => {}
} : {
  label?: string,
  size?: "small" | "medium" | "large",
  color?: "default" | "special" | "blurple" | "green" | "red" | "orange" | "blue"
  href?: string,
  target?: "_self" | "_blank" | "_parent" | "_top" | string,
  icon?: Icon,
  endIcon?: Icon,
  disabled?: boolean,
  loading?: boolean,
  onClick?: MouseEventHandler
}) {
  const StartIcon = icon;
  const EndIcon = endIcon;
  return (
    <div data-component="Button" data-size={size} data-color={color}>
      <button onClick={onClick} disabled={loading || disabled}>
        <span className="special-border">
          <span className="overflow" />
        </span>
        {!!href &&
          <Link to={href} target={target}>
            {(icon || (!endIcon && loading)) && (
              <span className="prefix">
                {loading ? <Loader className="loading-spin" /> : (StartIcon && <StartIcon />)}
              </span>
            )}
            <span className="label">{label}</span>
            {endIcon && (
              <span className="postfix">
                {loading && !icon ? <Loader className="loading-spin" /> : (EndIcon && <EndIcon />)}
              </span>
            )}
          </Link>
        }
        {!href && 
          <a>
            {(icon || (!endIcon && loading)) && (
              <span className="prefix">
                {loading ? <Loader className="loading-spin" /> : (StartIcon && <StartIcon />)}
              </span>
            )}
            <span className="label">{label}</span>
            {endIcon && (
              <span className="postfix">
                {loading && !icon ? <Loader className="loading-spin" /> : (EndIcon && <EndIcon />)}
              </span>
            )}
          </a>
        }
      </button>
    </div>
  );
}

export default Button;
