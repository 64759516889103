import { useRef, useEffect } from "react";

import Textbox from "../textbox/Textbox";
import ChatMessage, { ChatMessageEntry } from "./ChatMessage";

import "./ChatBox.scss";

export function ChatBox ({
  botName = 'Funct',
  botAvatar,
  userName = 'User',
  userAvatar,
  channelName = 'test-channel',
  welcomeMessage,
  messages = [],
  onSubmit
} : {
  botName?: string,
  botAvatar?: string,
  userName?: string,
  userAvatar?: string,
  channelName?: string,
  welcomeMessage?: string,
  messages?: ChatMessageEntry[],
  onSubmit?: (value: string) => void
}) {

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      const scrollEl = scrollRef.current;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [messages])

  return (
    <div data-component="ChatBox">
      <div className="scrollable" ref={scrollRef}>
        <div className="welcome">
          Welcome to #{channelName}!
        </div>
        <div className="description">
          {welcomeMessage ? welcomeMessage : (
            <>
              This is not a real channel, but you can use it to test <mark>@{botName}</mark>.
            </>
          )}
        </div>
        {messages.map((message, i) => {
          const msg = { ...message };
          if (msg.app) {
            if ((msg.avatar === null || msg.avatar === void 0) && botAvatar) {
              msg.avatar = botAvatar;
            }
            if (!msg.name && botName) {
              msg.name = botName;
            }
          } else {
            if ((msg.avatar === null || msg.avatar === void 0) && userAvatar) {
              msg.avatar = userAvatar;
            }
            if (!msg.name && userName) {
              msg.name = userName;
            }
          }
          return <ChatMessage key={i} {...msg} />;
        })}
      </div>
      {onSubmit && (
        <div className="fixed">
          <Textbox
            type="chat"
            lines={1}
            maxLines={8}
            placeholder="Message #test-channel"
            onSubmit={value => onSubmit(value)}
            />
        </div>
      )}
    </div>
  );

}