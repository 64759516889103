import { useRef, useState, useEffect } from "react";
import { RefreshCcw, X, Upload } from "react-feather";
import "./ImageUploader.scss";

export default function ImageUploader({
  placeholder,
  image,
  onChange
} : {
  placeholder?: string,
  image?: string,
  onChange?: (base64data: string | null | false) => void
}) {

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [ imageUrl, setImageUrl ] = useState(image);

  useEffect(() => {
    setImageUrl(image);
  }, [image]);
  
  const openFileUpload = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result as string;
        const base64data = url.split(',')[1];
        setImageUrl(url);
        onChange?.(base64data);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetImage = () => {
    setImageUrl(image);
    onChange?.(null);
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  const trashImage = () => {
    setImageUrl(void 0);
    if (image) {
      onChange?.(false);
    } else {
      onChange?.(null);
    }
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  return (
    <div data-component="ImageUploader">
      <div className="image-actions">
        {image && imageUrl !== image && <div className="reset" onClick={resetImage}><RefreshCcw /></div>}
        <div className="spacer" />
        {imageUrl && <div className="close" onClick={trashImage}><X /></div>}
      </div>
      <div className="image-actions bottom">
        <div className="spacer" />
        <div className="upload" onClick={openFileUpload}><Upload /></div>
      </div>
      <input
        type="file"
        accept="image/jpeg,image/png,.svg"
        ref={inputFileRef}
        onChange={handleFileSelect}
      />
      <div className="img"
        style={{backgroundImage: `url("${imageUrl || placeholder}")`}}
        onClick={openFileUpload}
        >
        { imageUrl || placeholder ? '' : 'No image selected' }
      </div>
    </div>
  );
};