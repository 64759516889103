import { Link, useLocation } from "react-router-dom";

import "./DashboardNav.scss";

export default function DashboardNav() {

  const location = useLocation();
  const pathname = location.pathname;

  const links = [
    {
      url: '/dashboard',
      title: 'Dashboard'
    },
    {
      url: '/profile',
      title: 'Profile'
    },
    {
      url: '/billing',
      title: 'Billing'
    }
  ];

  return (
    <div data-component="DashboardNav">
      {links.map(link => {
        const isActive = pathname === link.url || pathname.startsWith(link.url + '/');
        return (
          <div
            className={`nav-link ${isActive ? `nav-active` : ``}`}
            key={link.url}
          >
            <Link to={link.url}>{link.title}</Link>
          </div>
        );
      })}
    </div>
  );

}