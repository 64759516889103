import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import API from "../../../utils/api";

import { Save } from "react-feather";

import "./EditProfilePage.scss";
import Button from "../../../components/button/Button";
import Textbox from "../../../components/textbox/Textbox";

interface OrganizationSettings {
  display_name: string,
  about_me: string
};

export default function EditProfilePage() {

  const [isLoading, setIsLoading] = useState(false);
  const { organization }= useAuth();
  const navigate = useNavigate();

  const [ fields, setFields ] = useState<OrganizationSettings>({
    display_name: organization.display_name || '',
    about_me: organization.about_me || ''
  });

  const saveProfile = async () => {
    setIsLoading(true);
    try {
      await API.put(
        'v1/organizations',
        {
          name: organization.name,
          fields
        }
      );
    } catch (e) {
      const error = e as Error;
      alert(error.message);
    }
    await API.refreshUser();
    setIsLoading(false);
  };

  return (
    <div data-component="EditProfilePage">
      <div className="profile-edit">
        <div className="profile-row">
          <div className="profile-title">
            Display name
          </div>
          <div className="profile-entry">
            <Textbox
              placeholder={organization.name}
              value={fields.display_name || ''}
              onChange={value => {
                const f = { ...fields };
                f.display_name = value;
                setFields(f);
              }}
              />
          </div>
        </div>
        <div className="profile-row">
          <div className="profile-title">
            About me
          </div>
          <div className="profile-entry">
            <Textbox
              value={fields.about_me || ''}
              type="multiline"
              lines={3}
              maxLines={10}
              onChange={value => {
                const f = { ...fields };
                f.about_me = value;
                setFields(f);
              }}
              />
          </div>
        </div>
        <div className="profile-actions">
          <Button
            label="Save changes"
            color="green"
            icon={Save}
            loading={isLoading}
            onClick={() => saveProfile()}
            />
        </div>
      </div>
    </div>
  );

}