import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <div data-component="Footer">
      <div className="footer-column">
        <div className="footer-title">Navigation</div>
        <Link to="/">Home</Link>
        <Link to="/packages">Packages</Link>
        <Link to="/pricing">Pricing</Link>
      </div>
      <div className="footer-column">
        <div className="footer-title">Social</div>
        <Link to="https://discord.gg/funct">Discord</Link>
        <Link to="https://github.com/functme">GitHub</Link>
        <Link to="https://x.com/functme">X / @functme</Link>
      </div>
      <div className="footer-column">
        <div className="footer-title">Legal</div>
        <Link to="/privacy-policy">Privacy policy</Link>
        <Link to="/terms-of-service">Terms of service</Link>
      </div>
      <div className="spacer" />
      <div className="footer-column footer-right">
        <span className="copyright">
          <img src="/logo/funct-logo.svg" />
          <span>
            &copy; 2024 Funct, Inc.<br />
            Made in San Francisco
          </span>
        </span>
      </div>
    </div>
  )
};