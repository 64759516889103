import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";

import { AgentSchema, PackageEndpointSchema, StandaloneAgentSchema } from "../../utils/types";

import {
  Check,
  Plus,
  Save,
  X,
  Search,
  ArrowRight,
} from 'react-feather';
import { useAuth } from "../../context/AuthContext";
import Button from "../../components/button/Button";
import Toggle from "../../components/toggle/Toggle";
import ImageUploader from "../../components/uploader/ImageUploader";
import Textbox from "../../components/textbox/Textbox";
import API, { ErrorWithDetails } from "../../utils/api";
import { sleep } from "../../utils/sleep";
import { getTimeDelta, formatRecent } from "../../utils/time";

import { ChatMessageEntry, FunctionResult } from "../../components/chat/ChatMessage";

import "./AgentsPage.scss";
import { ChatBox } from "../../components/chat/ChatBox";

const serialize = (obj: any) => {
  obj = obj || {};
  if (obj && typeof obj === "object") {
    return Object.keys(obj).sort().map(key => {
      return [key, obj[key]].join('=');
    }).join('&');
  } else {
    return obj;
  }
};

export default function AgentsPage() {

  const location = useLocation();
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const { user, organization } = useAuth();

  const [agent, setAgent] = useState<AgentSchema | null>(null);
  const [botName, setBotName] = useState("");
  const [botPrompt, setBotPrompt] = useState("");
  const [botPackages, setBotPackages] = useState<{[key: string]: string}>({});
  const [updatedBotImageUrl, setUpdatedBotImageUrl] = useState<string | null | false>(null);

  /**
   * Dev mode: for testing functions
   */
  const [developerMode, setDeveloperMode] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [packageSearch, setPackageSearch] = useState("");
  const [packageEndpoints, setPackageEndpoints] = useState<Array<PackageEndpointSchema>>([]);
  const packageEndpointsRef = useRef(0);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<ChatMessageEntry[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<ErrorWithDetails | null>(null);

  const fetchPackageEndpoints = async (delay = 300) => {
    setIsSearching(true);
    const id = ++packageEndpointsRef.current;
    await sleep(delay);
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const endpointsResponse = await API.get(
      'v1/packages/endpoints/search',
      {query: packageSearch, limit: {count: 3, offset: 0}}
    );
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const packageEndpoints = (endpointsResponse?.data || []) as Array<PackageEndpointSchema>;
    setIsSearching(false);
    setPackageEndpoints(packageEndpoints);
  };

  // Use the `location` object to trigger a re-render when the query string changes
  useEffect(() => {
    const agentId = searchParams.get('agent_id');
    if (agentId) {
      fetchPackageEndpoints(0);
      (async () => {
        try {
          const agentResponse = await API.get(
            'v1/agents',
            {
              organization: organization.name,
              includeDiscord: true
            }
          );
          const agents = (agentResponse?.data || []) as AgentSchema[];
          const agent = agents.find(a => a.unique_id === agentId);
          const botName = agent?.agentConfigs?.[0]?.name || "";
          const botPrompt = agent?.agentConfigs?.[0]?.system_prompt || "";
          const botPackages = agent?.agentConfigs?.[0]?.enabled_packages || {};
          setAgent(agent || null);
          setBotName(botName);
          setBotPrompt(botPrompt);
          setBotPackages(botPackages);
        } catch (e) {
          setError(e as ErrorWithDetails);
        }
      })();
    }
  }, [location.search]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages])

  const updateAgent = async () => {
    if (!agent) {
      return;
    }
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    try {
      const agentResult = await API.put(
        "v1/agents",
        {
          id: agent.unique_id,
          name: botName,
          prompt: botPrompt,
          packages: botPackages,
          image: updatedBotImageUrl
            ? {_base64: updatedBotImageUrl}
            : updatedBotImageUrl, // false will delete, null will keep same
          includeDiscord: true
        }
      );
      setAgent(agentResult as AgentSchema);
      setUpdatedBotImageUrl(null); // Reset image url
      setError(null);
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  const sendSystemMessage = (value: string) => {
    const message: ChatMessageEntry = {
      name: 'System message',
      avatar: '',
      time: new Date(),
      app: true,
      message: value,
    };
    setMessages(messages => messages.concat(message));
  };

  const sendChatMessage = (value: string) => {
    const message: ChatMessageEntry = {
      time: new Date(),
      app: false,
      message: value
    };
    setMessages(messages => messages.concat(message));
    if (agent) {
      (async () => {
        try {
          const queryResult = await API.post(
            "v1/agents/requests",
            {
              id: agent.unique_id,
              query: value,
              developerMode: developerMode
            }
          );
          const responseAgent = queryResult?.agent as StandaloneAgentSchema;
          const response = queryResult?.response || "";
          const functs = (queryResult?.functions || []) as FunctionResult[];
          if (response) {
            const responseMessage: ChatMessageEntry = {
              time: new Date(),
              app: true,
              message: response,
              functions: functs
            };
            setMessages(messages => messages.concat(responseMessage));
          }
        } catch (e) {
          const error = e as Error;
          const responseMessage: ChatMessageEntry = {
            time: new Date(),
            app: true,
            message: error.message,
            functions: []
          };
          setMessages(messages => messages.concat(responseMessage));
        }
      })();
    }
  }

  return (
    <div data-component="AgentsPage">
      <div className="settings">
        <div className="scrollable">
          <div className="edit-name-and-avatar">
            <div className="edit-avatar">
              <ImageUploader
                image={agent?.agentConfigs?.[0]?.profile_image_url}
                onChange={base64Data => {
                  // false means delete
                  setUpdatedBotImageUrl(base64Data);
                }}/>
            </div>
            <div className="edit-name">
              <div className="form-row">
                <Textbox
                  value={botName}
                  placeholder="Enter agent name"
                  onChange={botName => setBotName(botName)}
                  />
              </div>
              <div className="discord-links">
                {agent?.discordAgents?.map(discordAgent => {
                  const discordLink = discordAgent.discordLink;
                  return (
                    <div className="discord-link-entry" key={discordLink.unique_id}>
                      <div className="icon">
                        <ArrowRight />
                      </div>
                      <div className="info">
                        in
                        &nbsp;<Link
                          to={`https://discord.com/channels/${discordLink.discord_guild_id}`}
                          target="_blank"
                        >
                          {discordLink.discord_guild_metadata.name}
                        </Link>
                        &nbsp;as&nbsp;
                        <strong>
                          {discordLink.discord_bot_metadata?.nick || '(unknown)'}
                        </strong>
                      </div>
                      <div className="spacer" />
                      {discordLink.discord_bot_metadata?.update_rate_limited_until && (
                        getTimeDelta(discordLink.discord_bot_metadata.update_rate_limited_until).sign === -1
                          ? (
                              <div className="warn">
                                unsynced, wait {formatRecent(discordLink.discord_bot_metadata.update_rate_limited_until).replace('in ', '')}
                              </div>
                            )
                          : (
                              <div className="warn">
                                save to resync
                              </div>
                            )
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="form-row">
            <Textbox
              heading="Agent personality"
              type="multiline"
              lines={3}
              maxLines={8}
              value={botPrompt}
              onChange={botPrompt => setBotPrompt(botPrompt)}
              />
          </div>
          <div className="form-row">
            {Object.keys(botPackages).map(name => {
              const version = botPackages[name];
              return (
                <Button
                  key={`${name}/${version}`}
                  label={`${name}/${version}`}
                  icon={X}
                  color={"default"}
                  size="small"
                  onClick={() => {
                    const pkgs = {...botPackages};
                    delete pkgs[name];
                    setBotPackages(pkgs);
                  }}
                  />
              );
            })}
          </div>
          <div className="form-row">
            <Textbox
              heading="Search for functs..."
              endIcon={Search}
              value={packageSearch}
              loading={isSearching}
              onChange={search => {
                setPackageSearch(search);
                fetchPackageEndpoints();
              }}
              />
          </div>
          <div className="form-row">
            <div className="package-endpoints">
              {packageEndpoints.map(packageEndpoint => {
                const info = packageEndpoint.function_info;
                return (
                  <div
                    key={packageEndpoint.function_info.name}
                    className="package-endpoint"
                    >
                    <div className="info">
                      <div className="title">
                        <span className="highlight">{info.summary.title}</span>
                        <span className="time">{formatRecent(packageEndpoint.created_at)}</span>
                      </div>
                      <div className="summary">
                        <span className={`method method-${info.method}`}>
                          {info.method}
                        </span>
                        <span>
                          {info.summary.identifier}{info.summary.endpoint ? '/' + info.summary.endpoint : ''}
                        </span>
                      </div>
                    </div>
                    <div className="spacer" />
                    <div className="action">
                      <Button
                        label={botPackages[info.package.name] === info.package.version ? `Added` : `Add`}
                        icon={botPackages[info.package.name] === info.package.version ? Check : Plus}
                        color={botPackages[info.package.name] === info.package.version ? "green" : "default"}
                        size="small"
                        onClick={() => {
                          const pkgs = {...botPackages};
                          if (pkgs[info.package.name] === info.package.version) {
                            delete pkgs[info.package.name];
                          } else {
                            pkgs[info.package.name] = info.package.version;
                          }
                          setBotPackages(pkgs);
                        }}
                        />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {error && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
        </div>
        <div className="form-row margin-top fixed">
          <div className="spacer" />
          <Button
            icon={Save}
            color="orange"
            disabled={(
              botName === (agent?.agentConfigs[0].name || "") &&
              botPrompt === (agent?.agentConfigs[0].system_prompt || "") &&
              serialize(botPackages) === serialize(agent?.agentConfigs[0].enabled_packages) &&
              updatedBotImageUrl === null
            )}
            label="Save changes"
            loading={isSaving}
            onClick={updateAgent}
            />
        </div>
      </div>
      <div className="chat">
        <div className="toggle-devmode">
          <Toggle
            label="Developer mode"
            value={developerMode}
            size="small"
            onClick={() => {
              const value = !developerMode;
              if (value) { 
                sendSystemMessage([
                  'Developer mode: ENABLED',
                  'Your bot will only respond in a limited fashion, but you can test functions without a rate limit.'
                ].join('\n'));
              } else {
                sendSystemMessage('Developer mode: DISABLED');
              }
              setDeveloperMode(value);
            }}
          />
        </div>
        <ChatBox
          botName={agent?.agentConfigs?.[0]?.name || void 0}
          botAvatar={agent?.agentConfigs?.[0]?.profile_image_url || void 0}
          userName={organization.name}
          messages={messages}
          onSubmit={value => sendChatMessage(value)}
        />
      </div>
    </div>
  );

}