import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { AgentSchema, DiscordLinkSchema, PackageEndpointSchema, PackageSchema } from "../../utils/types";

import {
  Check,
  Plus,
  RefreshCw,
  X,
  Search
} from 'react-feather';
import { ReactComponent as DiscordLogo } from "../../svg/discord-mark-white.svg";
import { useAuth } from "../../context/AuthContext";
import Button from "../../components/button/Button";
import Select from "../../components/select/Select";
import Textbox from "../../components/textbox/Textbox";
import API, { ErrorWithDetails } from "../../utils/api";
import { sleep } from "../../utils/sleep";
import { formatRecent } from "../../utils/time";

import "./SetupPage.scss";

type OnboardState = "init" |
  "loading" |
  "linked" |
  "choose_agent" |
  "set_prompt" |
  "set_functions" |
  "complete" |
  "error";

export default function SetupPage() {

  const location = useLocation();
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const { user, organization } = useAuth();

  const [botName, setBotName] = useState("");
  const [botPrompt, setBotPrompt] = useState("");
  const [botPackages, setBotPackages] = useState<{[key: string]: string}>({});

  const [isSearching, setIsSearching] = useState(false);
  const [packageSearch, setPackageSearch] = useState("");
  const [packageEndpoints, setPackageEndpoints] = useState<Array<PackageEndpointSchema>>([]);
  const packageEndpointsRef = useRef(0);

  const [onboardState, setOnboardState] = useState<OnboardState>("init");
  const [isSaving, setIsSaving] = useState(false);
  const [discordLink, setDiscordLink] = useState<DiscordLinkSchema | null>(null);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [agents, setAgents] = useState<AgentSchema[]>([]);
  const [error, setError] = useState<ErrorWithDetails | null>(null);

  // For link cancellations
  const [linkError, _] = useState(searchParams.get("error"));

  const fetchPackageEndpoints = async (delay = 300) => {
    setIsSearching(true);
    const id = ++packageEndpointsRef.current;
    await sleep(delay);
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const endpointsResponse = await API.get(
      'v1/packages/endpoints/search',
      {query: packageSearch, limit: {count: 3, offset: 0}}
    );
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const packageEndpoints = (endpointsResponse?.data || []) as Array<PackageEndpointSchema>;
    setIsSearching(false);
    setPackageEndpoints(packageEndpoints);
  };

  // Use the `location` object to trigger a re-render when the query string changes
  useEffect(() => {
    const discordLinkId = searchParams.get('discord_link_id');
    if (discordLinkId) {
      (async () => {
        setOnboardState("loading");
        try {
          const [linkResponse, agentsResponse] = await Promise.all([
            API.get('v1/discord_links', {id: discordLinkId}),
            API.get('v1/agents', {organization: organization.name})
          ]);
          const discordLink = linkResponse as DiscordLinkSchema;
          const agents = (agentsResponse?.data || []) as AgentSchema[];
          setDiscordLink(discordLink);
          setSelectedAgentId(discordLink?.discordAgent?.agent.unique_id || "");
          setAgents(agents);
          const botName = discordLink?.discordAgent?.agent?.agentConfigs?.[0]?.name || "";
          const botPrompt = discordLink?.discordAgent?.agent?.agentConfigs?.[0]?.system_prompt || "";
          const botPackages = discordLink?.discordAgent?.agent?.agentConfigs?.[0]?.enabled_packages || {};
          setBotName(botName);
          setBotPrompt(botPrompt);
          setBotPackages(botPackages);
          if (agents.length) {
            setOnboardState("choose_agent");
          } else {
            setOnboardState("linked");
          }
          // if (botName && botPrompt && Object.keys(botPackages).length) {
          //   setOnboardState("complete");
          // } else if (botName && botPrompt) {
          //   setOnboardState("set_functions");
          // } else if (botName) {
          //   setOnboardState("set_prompt");
          // }
        } catch (e) {
          setError(e as ErrorWithDetails);
          setOnboardState("error");
        }
      })();
    }
  }, [location.search]);

  useEffect(() => {
    if (onboardState === "set_functions") {
      fetchPackageEndpoints(0);
    }
  }, [onboardState]);

  const createDiscordLink = async () => {
    try {
      const linkResult = await API.post(
        'v1/discord_links',
        {
          organization: organization.name,
          successPath: '/setup'
        }
      );
      const url = linkResult?.url;
      if (url) {
        window.location = url;
      }
    } catch (e) {
      const error = e as ErrorWithDetails;
      alert(error.message);
    }
  };

  const chooseAgent = async () => {
    if (isSaving) {
      return;
    }
    if (!discordLink) {
      alert("Not linked");
      return;
    }
    setIsSaving(true);
    try {
      if (selectedAgentId !== discordLink.discordAgent?.agent.unique_id) {
        let agentId = selectedAgentId;
        const linkResult = await API.put(
          'v1/discord_links',
          {
            id: discordLink.unique_id,
            agent_id: agentId
          }
        );
        let newDiscordLink = linkResult as DiscordLinkSchema;
        const botName = newDiscordLink?.discordAgent?.agent?.agentConfigs?.[0]?.name || "";
        const botPrompt = newDiscordLink?.discordAgent?.agent?.agentConfigs?.[0]?.system_prompt || "";
        const botPackages = newDiscordLink?.discordAgent?.agent?.agentConfigs?.[0]?.enabled_packages || {};
        setBotName(botName);
        setBotPrompt(botPrompt);
        setBotPackages(botPackages);
        setDiscordLink(newDiscordLink);
        if (agentId) {
          setOnboardState("complete");
        } else {
          setOnboardState("linked");
        }
      } else {
        setOnboardState("complete");
      }
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  const updateBotName = async () => {
    if (isSaving) {
      return;
    }
    if (!discordLink) {
      throw new Error(`Can not update bot name without valid discordLink`);
    }
    setIsSaving(true);
    try {
      let agent = discordLink.discordAgent?.agent;
      if (!agent) {
        // for new bot flows create the agent first
        const agentResult = await API.post(
          "v1/agents",
          {
            organization: organization.name,
            name: botName,
            prompt: `<auto>`
          }
        );
        agent = agentResult as AgentSchema;
        const linkResult = await API.put(
          'v1/discord_links',
          {
            id: discordLink.unique_id,
            agent_id: agent.unique_id
          }
        );
        setDiscordLink(linkResult as DiscordLinkSchema);
        setBotPrompt(agent.agentConfigs[0].system_prompt);
        setOnboardState("set_prompt");
      } else {
        const agentResult = await API.put(
          "v1/agents",
          {
            id: agent.unique_id,
            name: botName,
            prompt: `<auto>`
          }
        );
        agent = agentResult as AgentSchema;
        let linkResult = {...discordLink};
        linkResult.discordAgent.agent = agent;
        setDiscordLink(linkResult);
        setBotPrompt(agent.agentConfigs[0].system_prompt);
        setOnboardState("set_prompt");
      }
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  const updateBotPrompt = async () => {
    if (isSaving) {
      return;
    }
    if (!discordLink) {
      throw new Error(`Can not update bot name without valid discordLink`);
    }
    setIsSaving(true);
    try {
      let agent = discordLink.discordAgent?.agent;
      if (!agent) {
        throw new Error(`Missing agent`);
      } else {
        const agentResult = await API.put(
          "v1/agents",
          {
            id: agent.unique_id,
            prompt: botPrompt
          }
        );
        agent = agentResult as AgentSchema;
        let linkResult = {...discordLink};
        linkResult.discordAgent.agent = agent;
        setDiscordLink(linkResult);
        setOnboardState("set_functions");
      }
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  const updateBotPackages = async () => {
    if (isSaving) {
      return;
    }
    if (!discordLink) {
      throw new Error(`Can not update bot packages without valid discordLink`);
    }
    setIsSaving(true);
    try {
      let agent = discordLink.discordAgent?.agent;
      if (!agent) {
        throw new Error(`Missing agent`);
      } else {
        const agentResult = await API.put(
          "v1/agents",
          {
            id: agent.unique_id,
            packages: botPackages
          }
        );
        agent = agentResult as AgentSchema;
        let linkResult = {...discordLink};
        linkResult.discordAgent.agent = agent;
        setDiscordLink(linkResult);
        setOnboardState("complete");
      }
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  return (
    <div data-component="SetupPage">
      {onboardState === "init" && (
        <div className="content">
          🧙 Thanks for joining Funct, {organization.name}!<br /><br />
          ✨ To build a custom AI bot, next we will install the Funct app.<br /><br />
          <Button
            icon={DiscordLogo}
            label="Install Funct"
            color="blurple"
            onClick={createDiscordLink}
            />
          {linkError && (
            <div className="form-row margin-top error">
              {linkError}
            </div>
          )}
        </div>
      )}
      {onboardState === "loading" && (
        <div className="content">
          Loading ...
        </div>
      )}
      {onboardState === "linked" && (
        <div className="content">
          <span>
            👾&nbsp;
            <a
              href={`https://discord.com/channels/${discordLink?.discord_guild_id}`}
              target="_blank"
            >
              {discordLink?.discord_guild_metadata.name}
            </a> seems like a cool server!
          </span><br /><br />
          Now, let's name your bot.<br />
          ( We can change this later. )<br /><br />
          <div className="form-row">
            <Textbox
              heading="bot name"
              value={botName}
              onChange={botName => setBotName(botName)}
              onSubmit={updateBotName}
              />
          </div>
          {error && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
          <div className="form-row margin-top">
            <div className="spacer" />
            <Button
              disabled={!botName.trim()}
              label="looks good!"
              loading={isSaving}
              onClick={updateBotName}
              />
          </div>
        </div>
      )}
      {(onboardState === "choose_agent") && (
        <div className="content">
          <span>
            👾&nbsp;
            <a
              href={`https://discord.com/channels/${discordLink?.discord_guild_id}`}
              target="_blank"
            >
              {discordLink?.discord_guild_metadata.name}
            </a> needs you to set up a bot.<br /><br />
            You can choose an agent you've already built, or
            create a new one.
          </span>
          <br /><br />
          <div className="form-row">
            <Select
              heading="choose agent"
              options={[
                {label: "✨ Create new agent", value: ""},
                {label: "─", value: "", disabled: true}
              ].concat(agents.map(agent => {
                return {
                  label: agent.agentConfigs[0].name +
                    (
                      agent.unique_id === discordLink?.discordAgent?.agent.unique_id
                        ? " (active)"
                        : ""
                    ),
                  value: agent.unique_id
                };
              }))}
              value={selectedAgentId}
              onChange={value => {
                setSelectedAgentId(value);
              }}
              />
          </div>
          {error && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
          <div className="form-row margin-top">
            <div className="spacer" />
            <Button
              label="let's go"
              loading={isSaving}
              onClick={chooseAgent}
              />
          </div>
        </div>
      )}
      {(onboardState === "set_prompt") && (
        <div className="content">
          <span>
            🤖 <strong>{botName}</strong> needs a personality.
          </span><br /><br />
          Be as creative or specific as you want!<br />
          ( It will do the best it can. )
          <br /><br />
          <div className="form-row">
            <Textbox
              heading="personality"
              type="multiline"
              lines={3}
              maxLines={8}
              value={botPrompt}
              onChange={botPrompt => setBotPrompt(botPrompt)}
              onSubmit={updateBotPrompt}
              />
          </div>
          {error && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
          <div className="form-row margin-top">
            <div className="link">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setOnboardState("linked")
                }}
                >
                &lt; name
              </a>
            </div>
            <div className="spacer" />
            <Button
              disabled={!botPrompt.trim()}
              label="okay, continue"
              loading={isSaving}
              onClick={updateBotPrompt}
              />
          </div>
        </div>
      )}
      {(onboardState === "set_functions") && (
        <div className="content">
          <span>
            🚀 What can <strong>{botName}</strong> do?
          </span><br /><br />
          Your companion can also perform actions aka <em>functs</em>.
          These are created by developers in our community!
          <br />
          <div>
          {Object.keys(botPackages).map(name => {
            const version = botPackages[name];
            return (
              <Button
                key={`${name}/${version}`}
                label={`${name}/${version}`}
                icon={X}
                color={"default"}
                size="small"
                onClick={() => {
                  const pkgs = {...botPackages};
                  delete pkgs[name];
                  setBotPackages(pkgs);
                }}
                />
            );
          })}
          </div>
          <br />
          <div className="form-row">
            <Textbox
              heading="search for functs..."
              endIcon={Search}
              value={packageSearch}
              loading={isSearching}
              onChange={search => {
                setPackageSearch(search);
                fetchPackageEndpoints();
              }}
              />
          </div>
          <div className="form-row">
            <div className="package-endpoints">
              {packageEndpoints.map(packageEndpoint => {
                const info = packageEndpoint.function_info;
                return (
                  <div
                    key={packageEndpoint.function_info.name}
                    className="package-endpoint"
                    >
                    <div className="info">
                      <div className="title">
                        <span className="highlight">{info.summary.title}</span>
                        <span className="time">{formatRecent(packageEndpoint.created_at)}</span>
                      </div>
                      <div className="summary">
                        <span className={`method method-${info.method}`}>
                          {info.method}
                        </span>
                        <span>
                          {info.summary.identifier}{info.summary.endpoint ? '/' + info.summary.endpoint : ''}
                        </span>
                      </div>
                    </div>
                    <div className="spacer" />
                    <div className="action">
                      <Button
                        label={botPackages[info.package.name] === info.package.version ? `Added` : `Add`}
                        icon={botPackages[info.package.name] === info.package.version ? Check : Plus}
                        color={botPackages[info.package.name] === info.package.version ? "green" : "default"}
                        size="small"
                        onClick={() => {
                          const pkgs = {...botPackages};
                          if (pkgs[info.package.name] === info.package.version) {
                            delete pkgs[info.package.name];
                          } else {
                            pkgs[info.package.name] = info.package.version;
                          }
                          setBotPackages(pkgs);
                        }}
                        />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {error && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
          <div className="form-row margin-top">
            <div className="link">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setOnboardState("set_prompt")
                }}
                >
                &lt; personality
              </a>
            </div>
            <div className="spacer" />
            <Button
              label="all good!"
              loading={isSaving}
              onClick={updateBotPackages}
              />
          </div>
        </div>
      )}
      {onboardState === "complete" && (
        <div className="content">
          🍾 Congrats!<br /><br />
          🤖 <strong>{botName}</strong><br />
          is now in
          👾 <a
            href={`https://discord.com/channels/${discordLink?.discord_guild_id}`}
            target="_blank"
          >
            {discordLink?.discord_guild_metadata.name}
          </a>.<br /><br />
          <div className="form-row margin-top">
            <div className="link">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setOnboardState("set_functions")
                }}
                >
                &lt; functs
              </a>
            </div>
            <div className="spacer" />
            <Button
              label="return to dashboard"
              href={`/dashboard`}
              />
          </div>
        </div>
      )}
      {onboardState === "error" && (
        <div className="content">
          Oops! We encountered an error:<br /><br />
          {error?.message || "Unknown error"}<br /><br />
          <Button
            icon={RefreshCw}
            label="Try again"
            href="/setup"
            />
        </div>
      )}
    </div>
  );

}